import { render, staticRenderFns } from "./OtherStat.vue?vue&type=template&id=003b43c0&scoped=true&"
import script from "./OtherStat.vue?vue&type=script&lang=js&"
export * from "./OtherStat.vue?vue&type=script&lang=js&"
import style0 from "./OtherStat.vue?vue&type=style&index=0&id=003b43c0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003b43c0",
  null
  
)

export default component.exports